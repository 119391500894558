import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthSection from "./components/AuthSection";
import Content from "./components/Content";
import Header from "./components/Header";
import Loader from "./components/Loader";
import Notifications from "./components/Notifications";
import Sidebar from "./components/Sidebar";
import { SetAuth } from "./redux/actions/authActions";
import { SetUser } from "./redux/actions/userActions";
import { SetHeight, SetWidth } from "./redux/actions/windowActions";
import "./styles/App.scss";
import { connectToWebSocket } from "./utils/conntectToWebsocket";
import { generateUrl } from "./utils/generateUrl";
import { getProfile, validateToken } from "./utils/tokenUtils";
import { websocketManager } from "./utils/WebSocketManager";

const App = () => {
    const isAuth = useSelector((state: any) => state.authReducer.auth);
    const user = useSelector((state: any) => state.userReducer.user);
    const category = useSelector(
        (state: any) => state.categoryReducer.category
    );
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", () => {
            dispatch(SetWidth(window.innerWidth));
            dispatch(SetHeight(window.innerHeight));
        });
    }, []);

    useEffect(() => {
        const setUser = async () => {
            if (!isAuth) return;
            const profile = await getProfile();

            dispatch(SetUser(profile));
        };

        setUser();
    }, [isAuth]);

    useEffect(() => {
        const connectWebsocket = async () => {
            if (!category || !isAuth) return;

            const allowedUrl = ["/"];

            if (allowedUrl.includes(category.link)) {
                const userId = user?.id;

                if (user && userId) {
                    if (!user) {
                        dispatch(SetUser(user));
                    }

                    const url = generateUrl(
                        `/api/v1/user/ws/requisites/updates?connect_as_partner=true&client_id=${userId}`
                    );

                    websocketManager.connect(url);

                    await connectToWebSocket();
                }
            } else {
                websocketManager.close();
            }
        };

        connectWebsocket();
    }, [category, isAuth]);

    useEffect(() => {
        const handleUserAuth = async () => {
            setIsLoading(true);
            const response = await validateToken();

            if (response) {
                const profile = await getProfile();

                const isValid = response && profile;

                if (isValid) {
                    dispatch(SetUser(profile));
                    dispatch(SetAuth(isValid));
                }
            }

            setIsLoading(false);
        };

        handleUserAuth();
    }, []);

    return (
        <div className="App">
            {isLoading ? (
                <Loader />
            ) : isAuth ? (
                <>
                    <Header />

                    <div className="main">
                        <Sidebar />
                        <Content />
                    </div>

                    <Notifications />
                </>
            ) : (
                <AuthSection />
            )}
        </div>
    );
};

export default App;
