import { SET_HEIGHT, SET_WIDTH } from "../actionTypes/windowActionTypes";

const initialState = {
    width: window.innerWidth,
    height: window.innerHeight,
};

export const windowReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_WIDTH:
            return {
                ...state,
                width: action.payload,
            };
        case SET_HEIGHT:
            return {
                ...state,
                height: action.payload,
            };
        default:
            return state;
    }
};
