import { SET_HEIGHT, SET_WIDTH } from "../actionTypes/windowActionTypes";

export const SetWidth = (width: number) => {
    return {
        type: SET_WIDTH,
        payload: width,
    };
};

export const SetHeight = (height: number) => {
    return {
        type: SET_HEIGHT,
        payload: height,
    };
};
